<template>
    <div>
        Add
    </div>
</template>
<script>
export default {
    name: 'OfferEdit',
};
</script>
